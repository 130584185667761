import { datadogRum } from "@datadog/browser-rum";

export interface InitPageParams {
  datadogApplicationId: string;
  datadogClientToken: string;
  service: string;
  env: string;
  gtmId: string;
  gtmParams: string;
}

export function initDatadog({
  datadogApplicationId,
  datadogClientToken,
  service,
  env,
}: InitPageParams) {
  datadogRum.init({
    applicationId: datadogApplicationId,
    clientToken: datadogClientToken,
    site: "datadoghq.com",
    service,
    env,
    trackInteractions: true,
  });
}

export function buildGtmScript({ gtmId, gtmParams }: InitPageParams) {
  const script = document.createElement("script");
  script.type = "text/javascript";

  script.innerHTML = `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '${gtmParams}';f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${gtmId}');
  `;

  const noscript = document.createElement("noscript");
  noscript.innerHTML = `
    <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}${gtmParams}"
    height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
  `;

  return [script, noscript];
}
