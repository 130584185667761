import { ChakraProvider } from "@chakra-ui/react";
import { Global } from "@emotion/react";
import React, { useEffect, useRef } from "react";
import { setup as reactContentfulImageSetup } from "react-contentful-image";
import { theme } from "../src/theme";
import { buildGtmScript, initDatadog } from "../src/tracking";
import "focus-visible";

const media = {
  xs: `(min-width: ${theme.breakpoints.base})`,
  sm: `(min-width: ${theme.breakpoints.sm})`,
  md: `(min-width: ${theme.breakpoints.md})`,
  lg: `(min-width: ${theme.breakpoints.lg})`,
  xl: `(min-width: ${theme.breakpoints.xl})`,
  "2xl": `(min-width: ${theme.breakpoints["2xl"]})`,
  dpr2: `(min-resolution: ${96 * 1.5}dpi)`, // 1.5x devices and up get 2x images
  dpr3: "(min-resolution: ${96*2.5}dpi)", // 2.5x devices and up get 3x images
  portrait: "(orientation: portrait)",
  landscape: "(orientation: landscape)",
};

const variants = {
  default: {
    quality: 90,
    density: 1.5,
  },
  dpr2: {
    quality: 90,
    density: 2.5,
  },
  dpr3: {
    quality: 90,
    density: 3.5,
  },
};

reactContentfulImageSetup(media, variants);

export default function RootApp({ Component, pageProps }) {
  const gtmRef = useRef<HTMLDivElement>();
  const intercomRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if ("initPageParams" in pageProps) {
      initDatadog(pageProps.initPageParams);

      if (gtmRef.current) {
        gtmRef.current.innerHTML = "";

        const [script, noscript] = buildGtmScript(pageProps.initPageParams);

        gtmRef.current.appendChild(script);
        gtmRef.current.appendChild(noscript);
      }
    }

    if (intercomRef.current) {
      intercomRef.current.innerHTML = "";

      const intercomId = "pk3s5o53";

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.intercomSettings = {
        app_id: intercomId,
      };

      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = `https://widget.intercom.io/widget/${intercomId}`;

      intercomRef.current.appendChild(script);
    }
  }, [JSON.stringify(pageProps?.initPageParams)]);

  return (
    <ChakraProvider theme={theme}>
      <Global
        styles={`
          .js-focus-visible :focus:not([data-focus-visible-added]) {
            outline: none;
            box-shadow: none;
          }
   
          @font-face {
            font-family: 'Eina 01';
            src: url('/static/fonts/Eina01-Bold.woff2') format('woff2'),
                url('/static/fonts/Eina01-Bold.woff') format('woff');
            font-weight: bold;
            font-style: normal;
            font-display: swap;
          }
          
          @font-face {
            font-family: 'Eina 01';
            src: url('/static/fonts/Eina01-Regular.woff2') format('woff2'),
                url('/static/fonts/Eina01-Regular.woff') format('woff');
            font-weight: normal;
            font-style: normal;
            font-display: swap;
          }
          
          @font-face {
            font-family: 'Eina 01';
            src: url('/static/fonts/Eina01-SemiBold.woff2') format('woff2'),
                url('/static/fonts/Eina01-SemiBold.woff') format('woff');
            font-weight: 600;
            font-style: normal;
            font-display: swap;
          }
          
          @font-face {
            font-family: 'Eina 01';
            src: url('/static/fonts/Eina01-SemiboldItalic.woff2') format('woff2'),
                url('/static/fonts/Eina01-SemiboldItalic.woff') format('woff');
            font-weight: 600;
            font-style: italic;
            font-display: swap;
          }
          
          @font-face {
            font-family: 'Eina 01';
            src: url('/static/fonts/Eina01-LightItalic.woff2') format('woff2'),
                url('/static/fonts/Eina01-LightItalic.woff') format('woff');
            font-weight: 300;
            font-style: italic;
            font-display: swap;
          }
          
          @font-face {
            font-family: 'Eina 01 RegularItalic';
            src: url('/static/fonts/Eina01-RegularItalic.woff2') format('woff2'),
                url('/static/fonts/Eina01-RegularItalic.woff') format('woff');
            font-weight: normal;
            font-style: italic;
            font-display: swap;
          }
          
          @font-face {
            font-family: 'Eina 01';
            src: url('/static/fonts/Eina01-BoldItalic.woff2') format('woff2'),
                url('/static/fonts/Eina01-BoldItalic.woff') format('woff');
            font-weight: bold;
            font-style: italic;
            font-display: swap;
          }
          
          @font-face {
            font-family: 'Eina 01';
            src: url('/static/fonts/Eina01-Light.woff2') format('woff2'),
                url('/static/fonts/Eina01-Light.woff') format('woff');
            font-weight: 300;
            font-style: normal;
            font-display: swap;
          }
        `}
      />
      <Component {...pageProps} />
      <div ref={gtmRef} />
      <div ref={intercomRef} />
    </ChakraProvider>
  );
}
